import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/elements/SEO";
import BlogLayout from "../layouts/BlogLayout";

function BlogPost(props) {
  const post = props.data.markdownRemark;
  const url = props.data.site.siteMetadata.siteUrl;
  const { title, description, date, author } = post.frontmatter;
  const thumbnail = post.frontmatter.image.childImageSharp.resize.src;
  const pathname = props.location.pathname;

  return (
    <BlogLayout>
      <SEO
        title={title}
        description={description}
        thumbnail={url + thumbnail}
        url={url}
        pathname={props.location.pathname}
      />
      <div className="blog-container">
        <Img fluid={post.frontmatter.image.childImageSharp.fluid} />
        <h1>{title}</h1>
        <h2 className="blog-infos">
          Publié le {date} par {author}
        </h2>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <div className="blog-share">
          <span className="blog-share-title bold">Partager :</span>
          <br />
          <a
            className="twitter-share-button"
            href={
              "https://twitter.com/intent/tweet?text=" +
              title +
              "&amp;via=_selfcity&amp;url=" +
              url +
              pathname
            }
            target="_blank"
            rel="noopener nofollow"
            aria-label="Partager sur twitter"
            data-size="large"
          >
            <img
              src="/img/twitter-o_32x32.png"
              alt="twitter"
              className="img-social"
            />
          </a>
          <a
            href={
              "https://www.facebook.com/sharer/sharer.php?u=" +
              url +
              pathname +
              ""
            }
            target="_blank"
            rel="noopener nofollow"
            aria-label="Partager sur facebook"
          >
            <img
              src="/img/facebook-o_32x32.png"
              alt="facebook"
              className="img-social"
            />
          </a>
        </div>
      </div>
    </BlogLayout>
  );
}

export default BlogPost;

export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        author
        description
        date(formatString: "DD-MM-YYYY")
        image {
          childImageSharp {
            resize(width: 1000, height: 1000) {
              src
            }
            fluid(maxWidth: 786) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
